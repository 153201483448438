<template>
  <div class="header">
    <router-link class="about_link" to="/">
      <div class="logo_box">
        <img class="logo" src="./assets/logo.png" alt="" />
        <span class="logo_text">Askbox提问箱</span>
      </div>
    </router-link>
    <div class="header_right">
      <!-- <a class="twitter_text" style="margin-right:20px;" href="https://www.askbox.ink/h5/square.html">
                广场
            </a>
            <a class="twitter_text" href="https://www.askbox.ink/h5/verify.html">
                登录
            </a> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.header_right {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 769px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 16px 0;
  }

  .logo_box {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .logo {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  .logo_text {
    font-family: MotoyaLMaru-W3-90ms-RKSJ-H, MotoyaLMaru-W3-90ms-RKSJ;
    color: #00ccf2;
    font-weight: 400;
    font-size: 15px;
  }

  .concat_box {
    display: flex;
    align-items: center;
  }

  .twitter_logo {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  .twitter_text {
    color: #333;
    font-size: 14px;
    white-space: nowrap;
    color: #00ccf2;
    cursor: pointer;
  }
}

@media screen and (min-width: 769px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    min-height: 70px;
    padding: 22px 71px 0;
    background: #fff;
  }

  .logo_box,
  .concat_box {
    display: flex;
    align-items: center;
  }

  .logo_box {
    cursor: pointer;
  }

  .logo {
    width: 48px;
    height: 48px;
    margin-right: 22px;
  }

  .logo_text {
    color: #00ccf2;
    font-weight: normal;
    font-size: 22px;
  }

  .twitter_logo {
    width: 28px;
    height: 28px;
    margin-right: 4px;
  }

  .twitter_text {
    color: #00ccf2;
    cursor: pointer;
    font-weight: normal;
    font-size: 20px;
    white-space: nowrap;
  }
}
</style>
