<template>
    <div id="app">
        <Header/>
        <router-view></router-view>
        <div class="down_box">
            <!-- <a class='ios_down_btn down_btn' href="https://apps.apple.com/app/id1611107911"></a>
            <a class='android_down_btn down_btn' href="https://static.askbox.ink/server/askbox.apk"></a> -->
            <div @click="download(0)" class='ios_down_btn down_btn'></div>
            <div @click="download(1)" class='android_down_btn down_btn'></div>
        </div>
        <Footer/>
        <WechatGuide :select='select' @switch="select = false"></WechatGuide>
    </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import WechatGuide from './components/WechatGuide/index.vue';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        WechatGuide,
    },
    data() {
        return {
            select: false,
            wx: false,
            weibo: false,
            qq: false,
        };
    },
    created() {
        this.getENV();
    },
    methods: {
        // 判断当前浏览器环境
        getENV() {
            const ua = window.navigator.userAgent.toLowerCase();
            const flag = this.source;
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                this.wx = true;
            } else if (ua.match(/WeiBo/i) == 'weibo') {
                this.weibo = true;
            } else if (ua.match(/\sqq/i) == ' qq') {
                this.qq = true;
            } else if (/tape/i.test(ua) || /-qy/i.test(ua) || /-qingyan/i.test(ua)) {
                this.tape = true;
            } else {
                if (ua.match(/safari/i) == 'safari') this.safari = true;
            }
        },
        // 用户点击引导下载
        download(isIos) {
            if (this.wx || this.weibo) {
                this.select = true;
                // location.hash = '#download';
                if (this.ios) {
                    self.location =
                'https://apps.apple.com/app/id1611107911';
                }
            } else {
                if (!isIos) {
                    self.location =
                    'https://apps.apple.com/app/id1611107911';
                } else {
                    self.location =
                    'https://static.askbox.ink/server/askbox.apk';
                }
            }
        },
    },
};
</script>

<style>
@import url("./assets/reset.css");

/* @font-face {
    font-family: rounded;
    src: url("./assets/rounded.ttf");
} */

html,
body {
    height: 100%;
    /* font-family: rounded; */
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media screen and (max-width: 769px) {
    html{
        font-size: 13.333vw;
    }
    .down_box {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }

    .ios_down_btn {
        display: inline-block;
        width: 109px;
        height: 40px;
        background-image: url("Assets/ios_btn.png");
        background-size: 100% 100%;
    }

    .android_down_btn {
        display: inline-block;
        width: 109px;
        height: 40px;
        margin-left: 28px;
        background-image: url("Assets/google_btn.png");
        background-size: 100% 100%;
    }
}

@media screen and (min-width: 769px) {
    .down_box {
        display: none;
    }
}
</style>
