import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

new Vue({
    router,
    beforeCreate() {
        const url = window.location.href || '';
        const reg = /popiask.com\/ad/i;
        const ua = window.navigator.userAgent.toLowerCase();
        let ios = false;
        let andr = false;
        if (ua.match(/iphone os/i) == 'iphone os') {
            ios = true;
        } else if (ua.match(/android/i) == 'android') {
            andr = true;
        }
        if (reg.test(url)) {
            if (ios) {
                location.href = 'https://itunes.apple.com/app/id1611107911';
            } else if (andr) {
                location.href = 'https://play.google.com/store/apps/details?id=com.tape.popi';
            }
        }
    },
    render: h => h(App),
}).$mount('#app');
