<template>
    <div class="wrapper" v-if='select'>
        <img class="jiantou" src="./imgs/pop-jiantou.png" alt="">
        <div>
            <div class="flex-box">
                <span>1.点击右上角的</span>
                <img src="./imgs/pop-more.png" alt="">
            </div>
            <div class="flex-box broswer">
                <span>2.选择在浏览器</span>
                <img src="./imgs/pop-brower.png" alt="">
                <span>中打开</span>
            </div>
        </div>
        <div class="btn" @click="$emit('switch')">我知道了</div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        select: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    methods: {},
};
</script>
<style lang="less" scoped>
span{
    font-size: 0.28rem;
    font-weight: 400;
    color: #FFFFFF;
}
.wrapper{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8.9vh 0.60rem 0 0;
    box-sizing: border-box;
}
.jiantou{
    margin-left: auto;
    margin-bottom: -0.1rem;
    width: 1.74rem;
    height: 1.46rem;
}
.flex-box{
    display: flex;
    align-items: center;
    >img{
        width: 0.60rem;
        height: 0.60rem;
        margin: 0 0.20rem;
    }
}
.broswer{
    padding: 0.40rem 0 0.98rem;
}
.btn{
    width: 2.00rem;
    height: 0.60rem;
    border-radius: 0.30rem;
    border: 2px solid #FFFFFF;
    line-height: 0.60rem;
    text-align: center;
    font-size: 0.28rem;
    font-weight: 400;
    color: #FFFFFF;
}
</style>
