<template>
    <div class="footer">
        <div class="policy_box">
            <router-link class="about_link" to="/about">联系我们</router-link>
            <!-- <a class="terms_link" href="https://popiask.com/userAgreement.html">用户协议</a>
            <a class='agreement_link' href="https://popiask.com/privacy.html">隐私政策</a> -->
        </div>
        <p class="copyright">山东庚辰义成网络技术有限公司 鲁ICP备2022023034号-5</p>
    </div>
</template>

<script>
export default {
};
</script>
<style lang='less' scoped>
.copyright {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    color: #666;
    font-weight: 500;
}

a {
    color: #00ccf2;
    text-decoration: underline;
}

@media screen and (max-width: 769px) {
    .footer {
        padding: 12px 0;
        font-size: 12px;
    }

    .policy_box {
        display: flex;
        justify-content: center;
        font-weight: 500;
    }

    a {
        line-height: 12px;
    }

    .about_link {
        padding-right: 8px;
    }

    .terms_link {
        padding: 0 8px;
        border-right: 1px solid #c1c1c1;
        border-left: 1px solid #c1c1c1;
    }

    .agreement_link {
        padding-left: 8px;
    }

    .copyright {
        font-size: 10px;
        text-align: center;
    }
}

@media screen and (min-width: 769px) {
    .footer {
        padding-right: 57px;
        padding-bottom: 28px;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
    }

    .policy_box {
        display: flex;
        justify-content: flex-end;
        text-decoration: underline;
    }

    .about_link {
        padding-right: 8px;
    }

    .terms_link {
        padding: 0 8px;
        border-right: 1px solid #c1c1c1;
        border-left: 1px solid #c1c1c1;
    }

    .agreement_link {
        padding-left: 8px;
    }

    .copyright {
        font-size: 12px;
    }
}
</style>
